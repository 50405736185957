import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 10rem;
  padding: 0 2rem;
  align-items: flex-end;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const LeftContainer = styled.div`
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  @media screen and (max-width: 768px) {
    font-size: x-large;
  }
`;

const Divider = styled.div`
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
`

const Form = styled.form`
  background-color: gray;
  gap: 3rem;
  display: flex;
  padding: 1rem 2rem;
  button {
    background: var(--orange);
    color: white;
  }
  @media screen and (max-width: 768px){
    margin-left: -0.7rem;
  }
`

const Input = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: #d9d9d9;
`

const Join = () => {
  return (
    <Container>
      <LeftContainer>
      <Divider />
        <div>
          <span className="stroke-text">ready to </span>
          <span>level up</span>
        </div>
        <div>
          <span> your body </span>
          <span className="stroke-text">with us?</span>
        </div>
      </LeftContainer>
      <div>
        <Form>
          <Input type="email" placeholder="Enter your email address here" />
          <button className="btn">Join now</button>
        </Form>
      </div>
    </Container>
  )
}

export default Join;