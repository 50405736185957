import React from "react";
import styled from "styled-components";
import RightArrow from "../assets/rightArrow.png";
import { programsData } from "../data/programsData";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    margin-top: -6rem;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  gap: 5rem;
  font-size: 3rem;
  font-style: italic;
  color: white;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    flex-direction: column; 
    align-items: center;
    font-size: x-large;
    gap: 1rem;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column; 
  }
`;

const Item = styled.div`
  padding: 2rem;
  background-color: gray;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1;
  svg {
    fill: white;
    width: 2rem;
    height: 2rem;
  }
  span:nth-child(2) {
    font-size: 1rem;
    font-weight: 700;
  }
  span:nth-child(3) {
    font-size: .9rem;
    line-height: 25px;
  }
  div {
    display: flex;
    align-items: center;
    gap: 2rem;
    img {
      width: 1rem;
    }
  }
  &:hover {
    cursor: pointer;
    background: var(--planCard);
  }
`

const Programs = () => {
  return (
    <Container id="programs">
      <Header>
        <span className="stroke-text">EXPLORE OUR</span>
        <span>PROGRAMS</span>
        <span className="stroke-text">TO SHAPE YOU</span>
      </Header>
      <Wrapper>
        {programsData.map((item, index) => (
          <Item key={index}>
            {item.image}
            <span>{item.heading}</span>
            <span>{item.details}</span>
            <div>
              <span>Join Now</span>
              <img src={RightArrow} alt="" />
            </div>
          </Item>
        ))}
      </Wrapper>
    </Container>
  )
};

export default Programs;