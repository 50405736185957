import React from "react";
import styled from "styled-components";
import Image1 from "../assets/image1.png";
import Image2 from "../assets/image2.png";
import Image3 from "../assets/image3.png";
import Image4 from "../assets/image4.png";
import Tick from "../assets/tick.png";
import NBImage from "../assets/nb.png";
import ADImage from "../assets/adidas.png";
import NikeImage from "../assets/nike.png";

const Container = styled.div`
  display: flex;
  padding: 0 2rem;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  grid-gap: 1rem;
  display: grid;
  flex: 1 1;
  gap: 1rem;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(3,1fr);
  @media screen and (max-width: 768px) {
    grid-auto-rows: auto;
    overflow: hidden; 
  }
  img {
    object-fit: cover;
  }
  img:nth-child(1) {
    width: 12rem;
    height: 28rem;
    grid-row: 1/3;
    @media screen and (max-width: 768px) {
      height: 17rem;
      width: 7rem; 
    }
  }
  img:nth-child(2) {
    height: 16rem;
    width: auto;
    grid-column: 2/4;
    @media screen and (max-width: 768px) {
      height: 10rem;
      width: 15rem;
    }
  }
  img:nth-child(3) {
    grid-column: 2/3;
    grid-row: 2;
    width: 14rem;
    @media screen and (max-width: 768px) {
      width: 7rem;
    }
  }
  img:nth-child(4) {
    grid-column: 3/4;
    grid-row: 2;
    height: 11.2rem;
    width: 10rem;
    @media screen and (max-width: 768px) {
      height: 6rem;
      width: 7rem;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1 1;
  gap: 1rem;
  flex-direction: column;
  text-transform: uppercase;
  div {
    font-size: 3rem;
    font-weight: bold;
    color: white;
  }
`;

const Text = styled.span`
  font-weight: bold;
  color: var(--orange);
`;

const IconContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  img {
    height: 2rem;
    width: 2rem;
  }
  span {
    font-size: 1rem;
  }
`;

const Partner = styled.div`
  display: flex;
  gap: 1rem;
  img {
    width: 2.5rem;
  }
`

const Reasons = () => {
  return (
    <Container id="reasons">
      <ImageContainer>
        <img src={Image1} alt="" />
        <img src={Image2} alt="" />
        <img src={Image3} alt="" />
        <img src={Image4} alt="" />
      </ImageContainer>
      <TextContainer>
        <Text>some reasons</Text>
        <div>
          <span className="stroke-text">why </span>
          <span>choose us?</span>
        </div>
        <IconContainer>
          <img src={Tick} alt="" />
          <span>OVER 140+ EXPERT COACHS</span>
        </IconContainer>
        <IconContainer>
          <img src={Tick} alt="" />
          <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
        </IconContainer>
        <IconContainer>
          <img src={Tick} alt="" />
          <span>1 FREE PROGRAM FOR NEW MEMBER</span>
        </IconContainer>
        <IconContainer>
          <img src={Tick} alt="" />
          <span>RELIABLE PARTNERS</span>
        </IconContainer>
        <span>our partners</span>
        <Partner>
          <img src={NBImage} alt="" />
          <img src={ADImage} alt="" />
          <img src={NikeImage} alt="" />
        </Partner>
      </TextContainer>
    </Container>
  )
}

export default Reasons;
