import React from "react";
import styled from "styled-components";
import Tick from "../assets/whiteTick.png";
import { plansData } from "../data/plansData";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: -4rem;
  @media screen {
    margin-top: -2rem;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-size: 3rem;
  font-style: italic;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    font-size: x-large;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Blur1 = styled.div`
  width: 32rem;
  top: 10rem;
  height: 23rem;
  left: 0;
`;

const Blur2 = styled.div`
  width: 32rem;
  top: 10rem;
  height: 23rem;
  right: 0;
`;

const Card= styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
  color: white;
  background-color: var(--caloryCard);
  svg {
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
  }
  :nth-child(2) {
    transform: scale(1.1);
    background: var(--planCard);
    svg {
      fill: white;
    }
    button {
      color: var(--orange);
    }
  }
  span {
    font-size: .8rem;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1rem;
`;

const Price = styled.div`
  font-size: 3rem;
  font-weight: bold;;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  img {
    width: 1rem;
  }
`

const Plan = () => {
  return (
    <Container id="plans">
      <Header>
        <span className="stroke-text">ready to start</span>
        <span>your journey</span>
        <span className="stroke-text">now with us</span>
      </Header>
      <Blur1 className="blur" />
      <Blur2 className="blur" />
      <Wrapper>
        {plansData.map((item, index) => (
          <Card key={index}>
            {item.icon}
            <Title>{item.name}</Title>
            <Price>$ {item.price}</Price>
            {item.features.map((record, index) => (
              <Feature key={index}>
                <img src={Tick} />
                <span>{record}</span>
              </Feature>
            ))}
            <span>see more benefits {"->"}</span>
            <button className="btn">Join Now</button>
          </Card>
        ))}
      </Wrapper>
    </Container>
  )
};

export default Plan;