import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import Logo from "../assets/logo.png";
import BarImage from "../assets/bars.png"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const Image = styled.img`
  width: 10rem;
  height: 3rem;
  cursor: pointer;
`

const Navbar = styled.ul`
  display: flex;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    background-color: var(--appColor);
    position: fixed;
    right: 2rem;
    padding: 2rem;
    border-radius: 5px;
    z-index: 99;
  }
`;

const NavbarItem = styled.li`
  list-style: none;
  color: white;
  cursor: pointer;
  :hover {
    color: var(--orange);
  }
`;

const Bar = styled.div`
  position: fixed;
  right: 2rem;
  z-index: 99;
  background-color: var(--appColor);
  padding: 0.5rem;
  border-radius: 5px;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const Header = () => {
  const isMobile = window.innerWidth < 768;
  const [showMenu, setShowMenu] = useState(false);
  const handleClick = () => {
    setShowMenu(false);
  }
  return <Container>
    <Image src={Logo} alt="" />
    {isMobile && !showMenu ? (
      <Bar onClick={() => setShowMenu(!showMenu)}>
        <img src={BarImage} alt="" />
      </Bar>
      ) : (
      <Navbar>
        <NavbarItem><Link to="home" smooth onClick={handleClick}>Home</Link></NavbarItem>
        <NavbarItem><Link to="programs" smooth onClick={handleClick}>Programs</Link></NavbarItem>
        <NavbarItem><Link to="reasons" smooth onClick={handleClick}>Why us</Link></NavbarItem>
        <NavbarItem><Link to="plans" smooth onClick={handleClick}>Plans</Link></NavbarItem>
        <NavbarItem><Link to="testimonials" smooth onClick={handleClick}>Testimonials</Link></NavbarItem>
      </Navbar>
    )}
  </Container>
}

export default Header;