import './App.css';
import React from "react";
import Hero from './components/Hero';
import Programs from "./components/Programs";
import Reasons from './components/Reasons';
import Plan from './components/Plan';
import Testimonials from './components/Testimonials';
import Join from "./components/Join";
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Hero />
      <Programs />
      <Reasons />
      <Plan />
      <Testimonials />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
