import React from "react";
import styled from "styled-components";
import GithubImage from "../assets/github.png";
import InsImage from "../assets/instagram.png";
import LinkedinImage from "../assets/linkedin.png";
import Logo from "../assets/logo.png";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  box-sizing: border-box;
`;

const Divider = styled.div`
  border: 1px solid var(--lightgray);
  width: 100%;
  margin: 4rem 0;
`

const IconContainer = styled.div`
  display: flex;
  gap: 4rem;
  img {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 10rem;
  margin-bottom: 4rem;
`;

const Blur1 = styled.div`
  background: red;
  right: 15%;
  bottom: 0;
  -webkit-filter: blur(200px);
  filter: blur(200px);
  height: 12rem;
  width: 26rem;
`;

const Blur2 = styled.div`
  background: #ff7300;
  left: 15%;
  bottom: 0;
  -webkit-filter: blur(200px);
  filter: blur(200px);
  height: 12rem;
  width: 26rem;
`;

const Footer = () => {
  return (
    <Container>
      <Divider />
      <IconContainer>
        <img src={GithubImage} alt="" />
        <img src={InsImage} alt="" />
        <img src={LinkedinImage} alt="" />
      </IconContainer>
      <Image src={Logo} alt="" />
      <Blur1 className="blur" />
      <Blur2 className="blur" />
    </Container>
  )
}

export default Footer;